import {
  ExternalSystemRequestParams,
  ExternalSystemListResponse,
} from "./types";
import { fetchCoreApi } from "utils/fetch";

export const getExternalSystems = async (
  params: ExternalSystemRequestParams
): Promise<ExternalSystemListResponse> => {
  return await fetchCoreApi<ExternalSystemListResponse>({
    route: `/infrastructure/external/system/`,
    query: params,
  });
};
