import { FC } from "react";
import { Space, Alert } from "antd";
import s from "./ErrorsList.module.css";

type ErrorsListProps = {
  errors: string[];
};

export const ErrorsList: FC<ErrorsListProps> = ({ errors }) => {
  return (
    <Space direction="vertical" className={s.container}>
      {errors.map((e, i) => (
        <Alert key={i} showIcon message={e} type="error" />
      ))}
    </Space>
  );
};
