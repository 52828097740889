import { Product } from "dhEntities/product/model/types";
import { FC } from "react";
import Link from "next/link";
import { Card, Typography } from "antd";

const { Text, Paragraph, Title } = Typography;

type ProductListItemProps = {
  item: Product;
};

export const ProductListItem: FC<ProductListItemProps> = ({ item }) => {
  return (
    <Link href={`/products/${item.id}`} style={{ textDecoration: "none" }}>
      <Card hoverable size="small" title={item.name}>
        <Paragraph type="secondary">
          Алиас: <Text>{item.alias}</Text>
        </Paragraph>
      </Card>
    </Link>
  );
};
