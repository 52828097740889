import Link from "next/link";
import Head from "next/head";
import {
  TeamOutlined,
  BuildOutlined,
  DeploymentUnitOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { Typography, Button } from "antd";
import style from "styles/pages.module.css";
import { $myTeamsInvolvements } from "dhEntities/user/store";
import { getMyEmployeeInfoFx } from "dhEntities/emploee/model/events";
import { InvolvedTeamMember } from "dhEntities/emploee/model/types";
import { TeamsInvolvementsList } from "dhEntities/emploee/ui/TeamsInvolvementsList/TeamsInvolvementsList";
import { useStore } from "effector-react";
import { getProducts } from "dhEntities/product/model/api";
import { List } from "common/List/List";
import { IntegrationObjectListItem } from "dhEntities/integrationObject/ui/IntegrationObjectListItem/IntegrationObjectListItem";
import { getObjectsFx } from "dhEntities/integrationObject/model/events";
import { SystemListItem } from "dhEntities/externalSystem/ui/SystemListItem/SystemListItem";
import { getExternalSystems } from "dhEntities/externalSystem/model/api";
import { ProductListItem } from "dhEntities/product/ui/ProductListItem/ProductListItem";
const { Title } = Typography;

export default function HomePage() {
  const myTeamsInvolvements =
    useStore<InvolvedTeamMember[]>($myTeamsInvolvements);
  const involvesPending = useStore<boolean>(getMyEmployeeInfoFx.pending);
  return (
    <>
      <Head>
        <title>Информация обо мне</title>
      </Head>
      <Title level={2}>
        <TeamOutlined /> Мои команды
      </Title>
      <TeamsInvolvementsList
        isLoading={involvesPending}
        items={myTeamsInvolvements}
      />

      <Title level={2}>
        <ShopOutlined /> Мои продукты
      </Title>
      <List
        ListItem={ProductListItem}
        getItems={getProducts}
        filter={{ isMy: true }}
        pageSize={12}
      />

      <Title level={2}>
        <DeploymentUnitOutlined /> Мои Системы
      </Title>
      <List
        ListItem={SystemListItem}
        getItems={getExternalSystems}
        filter={{ isMy: true }}
        pageSize={12}
      />

      <Title level={2}>
        <BuildOutlined /> Мои интеграционные объекты
      </Title>
      <Link href="/integration-objects/create">
        <Button type="primary" className={style.btn}>
          Создать Интеграционный объект
        </Button>
      </Link>
      <List
        ListItem={IntegrationObjectListItem}
        getItems={getObjectsFx}
        filter={{ isMy: true }}
        pageSize={12}
      />
    </>
  );
}
