import { FC } from "react";
import styles from "./TeamsInvolvementsList.module.css";
import { InvolvedTeamMember } from "dhEntities/emploee/model/types";
import { TeamsInvolvementsListItem } from "dhEntities/emploee/ui/TeamsInvolvementsListItem/TeamsInvolvementsListItem";
import { Empty, Skeleton } from "antd";

type TeamsInvolvementsListProps = {
  items: InvolvedTeamMember[];
  isLoading?: boolean;
};

export const TeamsInvolvementsList: FC<TeamsInvolvementsListProps> = ({
  items = [],
  isLoading = false,
}) => {
  if (isLoading) {
    return (
      <div className={styles.grid}>
        {[1, 2, 3, 4].map((i) => (
          <Skeleton key={i} active />
        ))}
      </div>
    );
  }
  if (!items.length) {
    return <Empty description="Тут пока пусто" />;
  }
  return (
    <div className={styles.grid}>
      {items.map((item) => (
        <TeamsInvolvementsListItem key={item.id} item={item} />
      ))}
    </div>
  );
};
