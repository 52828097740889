import { FC } from "react";
import { Card, Typography } from "antd";
import { ExternalSystem } from "dhEntities/externalSystem/model/types";

type SystemListItemProps = {
  item: ExternalSystem;
};

const { Paragraph } = Typography;

export const SystemListItem: FC<SystemListItemProps> = ({ item }) => {
  return (
    <Card size="small" title={<>{item.name}</>}>
      <Paragraph>{item.description}</Paragraph>
    </Card>
  );
};
