import { Badge } from "antd";
import { Status } from "common/const/status.const";
import { StatusType } from "common/types/Status";
import styles from "./StatusBadgeRibbon.module.css";

import { FC } from "react";

export const StatusBadgeRibbon: FC<{
  status: StatusType;
  className?: string;
  children?: React.ReactNode;
}> = ({ status, children, className }) => {
  const getColor = (status: StatusType) => {
    switch (status) {
      case Status.PROTOTYPE:
        return "blue";
      case Status.STABLE:
        return "green";
      case Status.DEPRECATED:
        return "yellow";
      case Status.DISABLED:
        return "red";
      default:
        return "gray";
    }
  };

  return (
    <>
      <Badge.Ribbon
        text={status}
        className={`${className} ${styles.ribbon}`}
        placement="end"
        color={getColor(status)}
      >
        {children}
      </Badge.Ribbon>
    </>
  );
};
