import { Product, ProductsListRequest, ProductsListResponse } from "./types";
import { fetchCoreApi } from "utils/fetch";

const baseRoute = "/product";

export const getProducts = async (
  params: ProductsListRequest
): Promise<ProductsListResponse> => {
  return await fetchCoreApi<ProductsListResponse>({
    route: baseRoute,
    query: params,
  });
};

export const getProduct = async (id: string): Promise<Product> => {
  return await fetchCoreApi<Product>({
    route: `${baseRoute}/${id}`,
  });
};

export const createProduct = async (
  params: Omit<Product, "id">
): Promise<Product> => {
  return fetchCoreApi<Product>({
    method: "POST",
    route: baseRoute,
    body: params,
  });
};

export const updateProduct = async (params: Product): Promise<Product> => {
  const { id, ...body } = params;
  return fetchCoreApi<Product>({
    method: "PUT",
    route: `${baseRoute}/${id}`,
    body,
  });
};

export const deleteProduct = async (id: string): Promise<any> => {
  return fetchCoreApi<any>({
    method: "DELETE",
    route: `${baseRoute}/${id}`,
  });
};
