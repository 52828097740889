import { FC } from "react";
import { Card, Typography, Tag } from "antd";
import { InvolvedTeamMember } from "dhEntities/emploee/model/types";

type TeamsInvolvementsListItemProps = {
  item: InvolvedTeamMember;
};

const { Text, Paragraph } = Typography;

export const TeamsInvolvementsListItem: FC<TeamsInvolvementsListItemProps> = ({
  item,
}) => {
  const joinedAt = new Date(item.joinedAt).toLocaleDateString();
  const leftAt = item.leftAt
    ? new Date(item.leftAt).toLocaleDateString()
    : "настоящее время";
  return (
    <Card
      size="small"
      title={item.team.name}
      style={{
        opacity: item.isActive ? 1 : 0.5,
      }}
    >
      <Text strong>Роли:</Text>
      <Paragraph>
        {item.roles.map((r) => (
          <Tag>{r}</Tag>
        ))}
      </Paragraph>
      <Text strong>Период:</Text>
      <Paragraph>
        с <Text strong>{joinedAt}</Text> по <Text strong>{leftAt}</Text>
      </Paragraph>
    </Card>
  );
};
