import { FC, ReactElement, useEffect, useState } from "react";
import { Empty, Skeleton, Pagination, PaginationProps, Row, Col } from "antd";
import type { ApiError, ListResponse } from "utils/fetch";
import styles from "./List.module.css";
import { ErrorsList } from "common/ErrorsList/ErrorsList";
interface BaseItem {
  id: string;
}
type ListProps<T> = {
  initialItems?: T[];
  ListItem: FC<{ item: T }>;
  getItems: (params: any) => Promise<ListResponse<T>>;
  filter: Object;
  pageSize: number;
};

export const List = <T extends BaseItem>({
  initialItems = [],
  getItems,
  filter,
  ListItem,
  pageSize = 12,
}: ListProps<T>): ReactElement => {
  // TODO: оптимизировать рендер
  const [items, setItems] = useState<T[]>(initialItems);
  const [errors, setErrors] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(pageSize);

  const [totalItems, setTotalItems] = useState(0);

  const load = async (isFilterChanged: boolean) => {
    setLoading(true);
    const currentPage = isFilterChanged ? 1 : page;
    try {
      const resp = await getItems({
        ...filter,
        pageSize: size,
        startIndex: size * (currentPage - 1),
      });
      setItems(resp.items);
      setTotalItems(resp.totalItems);
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError?.errors?.length) {
        setErrors(apiError.errors.map((err) => err.message));
      }
    }
    if (isFilterChanged) {
      setPage(1);
    }
    setLoading(false);
  };
  useEffect(() => {
    load(true);
  }, [filter]);

  useEffect(() => {
    load(false);
  }, [page, size]);

  const handleChangePage: PaginationProps["onChange"] = (page) => {
    setPage(page);
  };

  const handleShowSizeChange: PaginationProps["onShowSizeChange"] = (
    _,
    size
  ) => {
    setSize(size);
  };

  if (isLoading) {
    return (
      <div className={styles.grid}>
        {[1, 2, 3, 4].map((i) => (
          <Skeleton key={i} active />
        ))}
      </div>
    );
  }

  if (errors.length > 0) {
    return <ErrorsList errors={errors} />;
  }

  if (!items.length) {
    return <Empty description="Тут пока пусто" />;
  }

  return (
    <div className={styles.list}>
      <Row gutter={16}>
        {items.map((item) => (
          <Col key={item.id} xs={24} md={12} lg={6} className={styles.col}>
            <ListItem item={item} />
          </Col>
        ))}
      </Row>
      <div className={styles.pagination}>
        {totalItems > pageSize && (
          <Pagination
            defaultCurrent={page}
            showSizeChanger
            pageSizeOptions={["12", "24", "48"]}
            total={totalItems}
            pageSize={size}
            onShowSizeChange={handleShowSizeChange}
            onChange={handleChangePage}
          />
        )}
      </div>
    </div>
  );
};
