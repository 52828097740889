import { FC } from "react";
import Link from "next/link";
import { IntegrationObject } from "dhEntities/integrationObject/model/types";
import { Card, Typography } from "antd";
import { StatusBadgeRibbon } from "common/Status/StatusBadgeRibbon/StatusBadgeRibbon";
import styles from "./IntegrationObjectListItem.module.css";

type IntegrationObjectListItemProps = {
  item: IntegrationObject;
};

const { Paragraph, Text } = Typography;

export const IntegrationObjectListItem: FC<IntegrationObjectListItemProps> = ({
  item,
}) => {
  return (
    <Link
      href={`/integration-objects/${item.id}`}
      style={{ textDecoration: "none" }}
    >
      <StatusBadgeRibbon status={item.status}>
        <Card
          hoverable
          size="small"
          title={
            <>
              {item.name} <Text type="secondary">({item.alias})</Text>
            </>
          }
        >
          <Paragraph>{item.description}</Paragraph>
        </Card>
      </StatusBadgeRibbon>
    </Link>
  );
};
